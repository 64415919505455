.wrapper > div:first-child {
  width: 100%;
  height: 489px;
}

.map_title_style {
  padding: 0 15px;
  max-width: calc(100% - 30px);
  min-width: calc(100% - 30px);
}
