.subsystem_layout {
}

.subsystem_title_style {
    width: calc(100% - 40px);
    min-height: 38.5px;
    max-height: 38.5px;
    padding: 20px;
    text-align: center;

    background-color: rgb(60, 204, 82);
}

.flexContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.subsystem_title {
    font-size: 19px;
    font-weight: 500;
}

.list_place {
    min-height: 160px;
    max-height: calc(100vh - 332px);
    overflow-x: hidden;
    overflow-y: auto;
}

.list_place ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.list_place li {
    /*min-height: 69.53333333333333px;*/
    min-height: 55px;
    cursor: pointer;
}

.list_place .active:hover,
.active {
    background-color: rgb(220, 220, 220);
}

.list_place li:hover {
    background-color: rgb(230, 230, 230);
}

.list_place a {
    text-decoration: none;
    color: black;
}

.susystem_item {
    width: 100%;
    height: 100%;
}

/* Scroll bar list place */
.list_place::-webkit-scrollbar {
    display: none;
}

/* for firefox scroll bar */
.list_place {
    scrollbar-width: none;
}

@media only screen and (max-width: 1164px) {
    .list_place {
        max-height: calc(100vh - 369px);
    }
}

@media only screen and (max-width: 609px) {
    .subsystem_title_style {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
    }

    .list_place {
        max-height: unset;
    }
}

@media only screen and (max-height: 650px) {
    .subsystem_title_style {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
    }

    .list_place {
        max-height: unset;
    }
}
