.welcome_placeholder {
    padding: 30px 20px;
    line-height: 1.5;
    font-weight: 700;
    color: white;
    background: #000;
}

.welcome_placeholder > div {
    position: absolute;
    bottom: 0;
    left: 5px;
}
.logo_normal{
    max-width: 306px!important;
    height: auto!important;
    margin: 10px 0!important;
    border-radius: 0!important;
    position: absolute;
    bottom: 20px;
    left: 10px;
}

.welcome_placeholder > div > div:first-of-type {
    justify-content: flex-start;
    max-width: 306px;
    height: 48px;
    margin: 0 5px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    background-color: black;

    cursor: pointer;
}

.welcome_placeholder > div > div:first-of-type > img {
    margin-left: 10px;
    border-radius: 50%;
    background-color: white;
}

.welcome_placeholder > div > div:first-of-type > p {
    padding-left: 10px;

}


.welcome_placeholder > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
}


.welcome_placeholder > div > div:last-of-type > img {
    padding: 5px;

    cursor: pointer;
}

@media screen and (max-width:991px){
    .logo_normal{
        margin: 20px 0 0 0!important;
        position: relative;
        bottom: 0;
        left: 0;
    }
}

@media only screen and (max-width: 991px) {
    .welcome_placeholder {
        text-align: center;
    }


    .welcome_placeholder > div {
        display: none;
    }
}

@media only screen and (max-width: 576px) {
    .welcome_placeholder {
        padding: 20px 20px 10px;
    }
}
