.wrapper > div:first-child {
    width: 100%;
    height: calc(100vh - 100px);
}

.wrapper_header > div:first-child {
    width: 100%;
    height: calc(100vh - 70px);
}


.wrapper_full > div:first-child {
    width: 100%;
    height: 100vh;
}

.alerts_width {
    width: calc(100% - 600px) !important;
    background-color: white !important;
    transition: transform 0.9s ease-out !important;
}

.alerts_width > button:first-of-type {
    --contentButton: 'Ειδοποιήσεις';
}

.width500 {
    width: 530px !important;
    scroll-behavior: smooth;
}

.statistics_btn {
    width: clamp(550px, calc(25% + 300px), 700px);
    transition: transform 0.5s ease-out;
}


.statistics_btn > button:first-of-type {
    top: calc(50% - 26px + 62px);
    background-color: rgb(16, 34, 50);
    --contentButton: 'Στατιστικά';
}

.category_bins {
    --contentButton: 'Λίστα Κάδων';
}

.category_envi {
    --contentButton: "Περιβ\/ντικοι ";
}


@media only screen and (max-width: 1500px) {
    .alerts_width {
        width: calc(100% - 390px) !important;

        transition: transform 0.6s ease-out !important;
    }

    .width500 {
        width: 320px !important;
    }
}

@media only screen and (max-width: 609px) {
    .statistics_btn > button:first-of-type {
        top: 100%;

        left: calc(50% - 13px - 52px);
    }

    .statistics_btn {
        width: 100%;
    }
}

@media only screen and (max-width: 575px) {
    .wrapper > div:first-child {
        height: calc(100vh - 140px);
    }
}


.pseudo_header {
    position: fixed;
    top: 0px;
    min-width: calc(100% - 100px);
    min-height: 40px;

    max-height: 40px;
    padding: 15px 50px;
    background-color: white;

    box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 12%);
    z-index: 3;
}

.pseudo_header img {
    flex: 0 1 auto;
    max-height: 40px;
    aspect-ratio: 281 / 29;

}
