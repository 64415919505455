.header {
    position: absolute;
    top: 0px;
    min-width: calc(100% - 100px);
    min-height: 40px;

    max-height: 40px;
    padding: 15px 50px;
    background-color: white;

    box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 12%);
    z-index: 3;
}

.flexContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.app_logo {
    flex: 0 1 auto;
    max-height: 40px;
    cursor: pointer;

    aspect-ratio: 353 / 29;
}

.sponsor_logo__container {
    flex: 0 1 227.75px;
    height: 40px;
    max-height: 40px;
}

.logo_normal {
    height: 50px;
}

.epsa_logo_absolute {
    position: absolute;
    height: 60px;
    transform: translate(calc((227.75px - 150.08px) / 2), -5px);
}


.changeLanguage__Wrapper {
    position: absolute;
    right: 10px;
    transform: translateY(8px);
}

.changeLanguage__Wrapper span {
    cursor: pointer;
}

.activeLanguage {
    font-weight: 700;
}

@media only screen and (max-width: 600px) {
    .epsa_logo_absolute {
        right: 0px;
        transform: translate(0, -5px);
    }
}

@media only screen and (max-width: 575px) {
    .header {
        flex-wrap: wrap;
        justify-content: center;
        min-height: 80px;
        max-height: 80px;
        padding: 15px;
    }

    .epsa_logo_absolute {
        right: unset;
        transform: translate(calc((227.75px - 150.08px) / 2), -5px);
    }
}
