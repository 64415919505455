.boxesContainer {
    max-height: calc(100vh - 160px);

    margin: 30px 0;
    overflow-y: auto;
}


.flexContainer {
    display: flex;
    flex-wrap: wrap;
}

.medium {
    min-height: 243px;
    max-height: 243px;
    overflow-y: hidden;
    /* background-color: rgb(251, 86, 6); */
}

.large {
    min-height: 528px;
    max-height: 528px;
    overflow-y: hidden;
    /* background-color: rgb(51, 213, 83); */
}

.bgColor{
    background: #FF8700;
}

.bgGreenColor{
    background: rgb(51, 213, 83)!important;
}

.map_box {
    min-width: 270px !important;
    max-width: 270px !important;
    min-height: 539px;
    max-height: 539px;
    padding: 16px 5px 5px !important;
    overflow-y: hidden;
}

/* Scroll bar style */

.boxesContainer::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F5F5F5;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.boxesContainer::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
}

.boxesContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgb(92, 99, 106);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.boxesContainer::-webkit-scrollbar-thumb:hover {
    background-color: #373B40;
}

/* for firefox scroll bar */
.boxesContainer {
    scrollbar-color: #373B40 #F5F5F5;
    scrollbar-width: thin;
}

.weatherBox {
    background-color: rgb(131, 56, 236);
}

.frameBox {
    background-color: #FB5606;
}

.diav {
    background-color: #9A3122;
}

.uv_style {
    background-color: #7698A7 !important;
}

.applicants {
    background-color: #CCC608;
}

.customCategory_university {
    background-color: rgb(51, 213, 83) !important;
}

.ianosEnergy {
    /*background-color: orangered;*/
    background: #076585; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #076585, #FFF); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #076585, #FFF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.parking {
    background-color: #929EAA;
}

@media only screen and (max-width: 991px) {
    .boxesContainer {
        max-height: calc(100vh - 268px);
        padding: 0 calc((100% - 876px) / 2);
    }
}

@media only screen and (max-width: 875px) {
    .boxesContainer {
        max-width: 587px;
        padding: 0 calc((100% - 587px) / 2);
    }
}

@media only screen and (max-width: 585px) {
    .boxesContainer {
        max-width: 300px;
        max-height: calc(100vh - 248px);
        margin-top: 10px;
        padding: 0 calc((100% - 300px) / 2);
    }
}

@media only screen and (max-width: 575px) {
    .boxesContainer {
        max-height: calc(100vh - 258px);
    }
}

@media only screen and (max-width: 544px) {
    .boxesContainer {
        max-height: calc(100vh - 282px);
    }
}

@media only screen and (max-width: 506px) {
    .boxesContainer {
        max-height: calc(100vh - 306px);
    }
}
