.boxEntitySubTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    max-width: 100%;
    min-height: 38px;
    max-height: 38px;
    margin: 0 auto;
    font-size: 14px;

    text-align: center;
}

.subTitleBold {
    font-weight: 700;
    
}
