.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    min-width: 100%;
    min-height: 30px;
    max-height: 30px;
}

.footer > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer > div > img:first-of-type {
    height: 27px;
}

.footer > div > img {
    height: 30px;
    padding: 0 2px;

    cursor: pointer;
}

.footer > div > img:last-of-type {
    height: 25px;
    margin-right: 20px;
}


.copyright {
    margin-left: 20px;
}


