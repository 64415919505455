.home_container {
    display: grid;
    grid-template-columns: 320px auto;
}


.leftPanel_Charts {
    width: 90% !important;
    transition: transform 0.6s ease-out !important;
}

.leftPanel_Charts > button {
    width: 36px;
    color: black;
    background-color: white;
}

.horizontal_side_panel_tableau {
    width: 90% !important;
    background-color: white !important;
    transition: transform 0.6s ease-out !important;
}

.horizontal_side_panel_tableau > button {
    top: calc(50% + 36px);
    width: 36px;
    background-color: #102232;
}

@media only screen and (max-width: 991px) {
    .home_container {
        grid-template-columns: 100%;
        grid-template-areas: "welcome" "box_placeholder";
    }
}

@media only screen and (max-width: 609px) {
    .horizontal_side_panel_tableau,
    .leftPanel_Charts {
        width: 100% !important;
    }

    .leftPanel_Charts > button {
        top: 100%;

        left: calc(50% - 18px);
        transform: translate(0%, -8px) rotate(90deg);
    }

    .horizontal_side_panel_tableau > button {
        top: 100%;

        left: calc(50% - 72px);
        transform: translate(0%, -8px) rotate(90deg);
    }

}
