.input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input input {
  height: 51px;
  line-height: 51px;
  padding: 0 20px;
  width: 100%;
  outline: none;
  font-size: 14px;
  color: #808080;
  margin: 0 0 16px 0;
  box-sizing: border-box;
  display: block;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 1px 3px 0px rgb(0 0 0 / 6%);
  font-weight: 700;
}

.input input:focus {
  transition: box-shadow 0.2s !important;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 7%);
}
