.tooltip {
  position: absolute;

  background-color: whitesmoke;
  box-shadow: 3px 3px 3px rgb(0 0 0 / 26%);
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  padding: 0px;
  max-height: 0px;

  transition: max-height 0.15s ease-out;
  transition: padding 0.15s ease-out;
  overflow: hidden;
  max-width: 350px;
  width: auto;
  z-index: 1;

  color: black;
}
