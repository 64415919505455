.graph {
    flex: 0 1 45%;
    max-width: 695px;
    margin: 10px 0;
    padding: 20px;
    border-radius: 12px;
    background-color: white;

    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
}

.graph_title {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
}

.graph_line {
    width: 100% !important;
}

.labels {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 18px;
}

.space_bottom {
    margin-bottom: 10px;

}


.period {
    margin-top: 10px;
    padding-top: 10px;
    text-align: center;

    border-top: 1px solid black;
}


.graph > h1 {
    font-size: 16px;
}

.labels > span h2 {
    font-size: 20px;
}

.graph h3 {
    font-size: 18px;
}

@media only screen and (max-width: 1020px) {
    .graph {
        flex: 0 1 100%;
        margin: 10px auto;
    }
}


/* for SMALL width container */
.labels_small {
    display: block;
    font-size: 15px;
}

.labels_small > span {
    display: flex;
    align-items: center;
}

.labels_small > span h2 {
    margin-right: 20px;
    font-size: 16px;
}

.labels_small > span h3 {
    padding-bottom: 8px;
    font-size: 18px;
}

@media only screen and (max-width: 565px) {
    .labels {
        font-size: 15px;
    }
}

@media only screen and (max-width: 420px) {
    .labels {
        display: block;
    }

    .labels > span {
        display: flex;
        align-items: center;
    }

    .labels > span h2 {
        margin-right: 20px;
        font-size: 16px;
    }

    .labels > span h3 {
        padding-bottom: 8px;
        font-size: 16px;
    }
}
