.small_statistics_container {
    margin-top: 40px;
    color: black;
}

.small_statistics_container > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    color: white;
}

.small_statistics_container > div:first-of-type > button {
    width: 100%;
    height: 100%;
    color: white;
    border-bottom: 1px solid white;
    border-radius: 9px 9px 0 0;

}

.button_switch {
    background-color: transparent;
}

.small_statistics_container > div:first-of-type > button.active {
    color: black;
    border-bottom: none;
    background-color: white;
}
.graph_line {
    width: 100% !important;
}

@media (hover: hover) {
    .small_statistics_container > div:first-of-type > button:hover {
        background-color: rgba(255, 255, 255, 0.15);
    }

    .small_statistics_container > div:first-of-type > button.active:hover {
        background-color: rgba(255, 255, 255, 1);
    }

}
